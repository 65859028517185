import { Button, Stack, TextField } from "@mui/material";
import LogoComponent from "../components/LogoComponent";
import BotMessageComponent from "../components/BotMessage";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TRANSITION_TIME_IN } from "../theme/gaimcontrol";

import { UserContext } from '../context/UserContext';

const AnnaText = "Hello, stranger. I'm here to help you navigate through this journey. " +
  "As we are headed to a might journey together, we might as well introduce ourselves. " +
  "My name is Anna. What’s yours?"

function AcquaintanceComponent() {
  const navigate = useNavigate()
  const { updateUser } = useContext(UserContext);
  
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateUser && updateUser(event.target.value, 0, undefined);
  }

  const onSubmit = () => {
    navigate("/quest0")
  }

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <LogoComponent />

      {/* Message from Anna */}
      <BotMessageComponent timeout={TRANSITION_TIME_IN} text={AnnaText}/>

      <TextField
        variant="standard"
        placeholder="Enter your name"
        onChange={onNameChange}
        onKeyDown={(event) => event.key === 'Enter' && onSubmit()}
        inputProps={{
          style: {textAlign: "center"}}
        }
      />

      <Button variant="contained" onClick={onSubmit} sx={{paddingX: 6, marginY: 6}}>Next</Button>
    </Stack>
  )
}

export default AcquaintanceComponent;
