import { Box, Fade, Stack, Typography } from "@mui/material";
import { MAX_WIDTH } from "../theme/gaimcontrol";

interface BotMessageComponentProps {
  text: string;
  hidden?: boolean;
  timeout: number;
}

function BotMessageComponent( props: BotMessageComponentProps ) {
  return (
    <Fade in={(props.hidden !== true)} timeout={props.timeout}>
    <Stack
      flexGrow={1}
      alignItems={"center"}
      direction={"row"}
      maxWidth={MAX_WIDTH}
      width={"100%"}
      spacing={3}
      my={6}
    >
      <Box width={"50px"} height={"50px"} component="img" src="/anna.png"/>
      <Box
        sx={{
          borderRadius: "12px 36px 36px 32px",
          paddingX: "20px",
          paddingY: "12px",
          backgroundColor: "#E2E2E2",
        }}
      >
        <Typography sx={{color: "black"}}>{props.text}</Typography>
      </Box>
    </Stack>
  </Fade>    
  )
}

export default BotMessageComponent;
