import { Box, Button, Stack, Typography } from "@mui/material";
import LogoComponent from "../components/LogoComponent";
import ProgressIndicatorComponent from "../components/ProgressIndicator";
import BotMessageComponent from "../components/BotMessage";
import { TRANSITION_TIME_IN } from "../theme/gaimcontrol";
import { useContext } from "react";
import UserContext from "../context/UserContext";

const outcomes: string[] = [
  "It seems that you don't have a problem with gambling. You're aware of your own habits and don't" +
    " let it interfere with other aspects of your life. This is fantastic! Your honesty has helped us " +
    "understand thateverything's under control.",
  "You've shown that you're in control of your gambling habits, and they haven't caused any " +
    "significant problems. You might have had some close calls, but you've managed to stay on track. " +
    "Keep up the good work!",
  "Our trip has revealed that you're experiencing some issues with your gambling. You " +
    "might be feeling guilty or anxious about it, and it's affecting your health and finances. Don't " +
    "worry, though - this is a great starting point for making positive changes.",
  "It appears that you've been struggling with problem gambling habits. This has led to negative " +
    "consequences in your life, such as financial difficulties or strained relationships. Remember, " +
    "acknowledging the issue is the first step towards recovery. We're here to support you on this journey."
]

function mapScore( score: number ): number {
  if (score === 0) {
    return 0;
  }
  if (score < 3) {
    return 1;
  }
  if (score < 8) {
    return 2;
  }
  return 3;
}

function ResultComponent() {
  const { score } = useContext(UserContext);

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <LogoComponent />

      <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <BotMessageComponent timeout={TRANSITION_TIME_IN} text={outcomes[mapScore(score)]}/>
        <Typography variant="h5">Earned badges:</Typography>
        <ProgressIndicatorComponent position={9} big/>

        <Button onClick={()=>window.location.assign('http://www.gaimcontrol.com/')} variant="contained" sx={{paddingX: 6, marginY: 3}}>Return to the website</Button>
      </Box>
    </Stack>
  )
}

export default ResultComponent;
