import { Fade, Grid, Slider, Stack, Typography } from "@mui/material";
import { MAX_WIDTH } from "../theme/gaimcontrol";

interface AnswerSelectorComponentProps {
  hidden?: boolean;
  options: string[];
  onChange?: ( event: Event, value: number | number[] ) => void;
  min: number;
  max: number;
  value: number;
  timeout: number;
}

function AnswerSelectorComponent( props: AnswerSelectorComponentProps ) {
  return (
    <Fade in={(props.hidden !== true)} timeout={props.timeout}>
    <Stack
      flexGrow={1}
      alignItems={"center"}
      maxWidth={MAX_WIDTH}
      spacing={3}
      px={6}
    >
      <Slider
        marks={props.max < 4} onChange={props.onChange} value={props.value} min={props.min} max={props.max} 
        sx={{
          width: "75%",
          '& .MuiSlider-track': {
            height: "10px",
            border: 'none',
            backgroundImage: "linear-gradient(90deg, #B5FF1B 0%, #FFC331 60.4%, #7D2BE5 100%)"
          },
          '& .MuiSlider-rail': {
            height: "10px",
          }
        }}
      />
      <Grid container spacing={2}>
        {
          props.options.map((option, index) => (
            <Grid item xs={3} key={index}>
              <Typography textAlign={"center"} variant="body2">{option}</Typography>
            </Grid>
          ) )
        }
      </Grid>
    </Stack>
  </Fade>    
  )
}

export default AnswerSelectorComponent;
