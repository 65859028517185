import { Box, Button, Stack } from "@mui/material";
import LogoComponent from "../components/LogoComponent";
import BotMessageComponent from "../components/BotMessage";
import { useContext, useState } from "react";
import { sprintf } from "sprintf-js";
import AnswerSelectorComponent from "../components/AnswerSelector";
import { useNavigate } from "react-router-dom";
import ProgressIndicatorComponent from "../components/ProgressIndicator";
import FancyMessageBox from "../components/FancyMessageBox";
import { TRANSITION_TIME_IN, TRANSITION_TIME_OUT } from "../theme/gaimcontrol";
import UserContext from "../context/UserContext";

interface Question {
  question: string
  options: string[]
  reaction: string
  title: string
}

interface QuestQuestionProps {
  index: number
}

const questions: Question[] = [
  {
    question: "Nice to meet you%s. Before we start this trip, let's get ready and know each " +
      "other better. Shall we? And remember, everything we discuss is just between you and me, so " +
      "please try to be honest. 😉 Let's cut to the chase. Have you ever felt that you might have a " +
      "problem with gambling?",
    options: [
      "No, not me!",
      "Sometimes I feet I might",
      "Actually, it is a recurrent thought. Most of the time is in my mind.",
      "Yes, I always do."],
    reaction: "Thanks for sharing that with me. Acknowledging you might have a problem is a " +
      "significant step towards understanding and managing any unwanted behaviour you might have. " +
      "And by answering this question, we've already collected the first item for our trip!",
    title: "You earned a badge!",
  },
  {
    question: "And what about others%s? Have people told you that you had a gambling problem, " +
      "regardless of what you think?",
    options: [
      "Not that I can recall.",
      "Some people sometimes.",
      "Yes, it happens most of the time.",
      "They always tell me that."],
    reaction: "Sometimes it's hard to listen when we don't like what they're saying. Being able " +
    "to do so with an open mind will ease our trip.",
    title: "Next step",
  },
  {
    question: "But let's go back to us and our feelings%s. Have you ever felt guilty about " +
      "gambling or about what happens when you do it?",
    options: [
      "Nope, never.",
      "Guilt was sometimes there.",
      "It is recurrent. Most of the time, gambling made me feel guilty.",
      "Hard yes. The guilt is always there."],
    reaction: "Identifying your feelings is a positive step towards self-understanding and an " +
      "important one in our trip. And believe it or not, we're almost halfway through!",
    title: "You're on track",
  },
  {
    question: "What about your health%s? Have you suffered any health problems, such as stress " +
      "or anxiety, because of gambling?",
    options: [
      "No, I am as healthy as an apple.",
      "Well, sometimes gambling caused me some problems.",
      "Most of the time, I gamble and feel physically unwell.",
      "Yes, definitely, every single time."],
    reaction: "Being able to understand how gambling affects your health is not an easy task " +
      "but will allow us to make positive changes. You've advanced a long way already! Keep on!",
    title: "Well done",
  },
  {
    question: "Of course, if we're talking about gambling, we're talking about money. Let's see " +
      "how we managed that%s. Think about the last 12 months, for example. Did you bet more than " +
      "you could really afford to lose?",
    options: [
      "Never.",
      "Sometimes I crossed that line.",
      "Most of the time, I've been in that situation.",
      "Yes, I always do."],
    reaction: "Thinking about our limits is a huge step to a healthy gaming. Well done! Our trip " +
      "is almost ready. I have to commend you for being so honest with me and yourself.",
    title: "Moving forward",
  },
  {
    question: "After gambling, did you come back another day to try to win back your losses%s?",
    options: [
      "No, I never did that.",
      "Sometimes I found myself doing that.",
      "Yes, most of the time I go back to win back my losses.",
      "That's my routine. I always do that."],
    reaction: "Identifying this kind of pattern would be really helpful to arrive at the place " +
      "you want to be. We are one step closer!",
    title: "Good progress",
  },
  {
    question: "Talking about what we bet%s, have you borrowed money or sold anything in order to bet?",
    options: [
      "No, never.",
      "Well, I did it a few times.",
      "Most of the time this is the way I do it.",
      "Definitely, I always borrow money or sell things in order to gamble."],
    reaction: "Recognizing the extremes we go to can be difficult. You are brave to face this " +
      "question. And we are almost there!",
    title: "You're moving forward",
  },
  {
    question: "We're almost done%s, only 2 more to go! We are still talking about the last 12 months. So, tell " +
      "me, has your gambling put your finances or those of your household in a tight spot?",
    options: [
      "No, my gambling has never affected my financial situation.",
      "I had some struggles, but not too often.",
      "Most of the time my financial situation is struggling due to my gambling.",
      "Yes, this is my everyday situation."],
    reaction: "Understanding the impact of gambling on your life is vital. Your sincerity is a step " +
      "towards recovery!",
    title: "Keep going",
  },
  {
    question: "We're almost done%s. We both know that gambling can be exciting. But did you notice if " +
      "you needed to bet more money in order to have the same excitement?",
    options: [
      "No, that didn't happen to me.",
      "On occasion, yes.",
      "Most of the time I have to increase the amount of money to have the same excitement.",
      "Yes, I always have to bet more in order to get that excitement."],
    reaction: "Being honest with yourself is a crucial step in this trip. Congratulations for this " +
      "accomplishment! And we've already finished!",
    title: "Full collection!",
  },
]

function QuestQuestion( props: QuestQuestionProps ) {
  const [min, setMin] = useState<number>(1);
  const [max, setMax] = useState<number>(100);
  const [value, setValue] = useState<number>(50);
  const [celebrate, setCelebrate] = useState<boolean>(false);
  const [questionHidden, setQuestionHidden] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [transitionTimeout, setTransitionTimeout] = useState<number>(TRANSITION_TIME_IN)
  const [animate, setAnimate] = useState<boolean>(false)
  const navigate = useNavigate();
  const { name, score, email, updateUser } = useContext(UserContext);

  const onValueChange = ( event: Event, value: number | number[] ) => {
    if (max > 3) {
      setMin(0);
      setMax(3);
      setValue(1);
    } else {
      setValue(value as number)
    }
  }

  const onSubmit = () => {
    setDisableSubmit(true)
    var url = "/quest" + (props.index + 1)
    if (props.index >= (questions.length - 1)) {
      url = "/fin"
    }
    setAnimate(true)
    setTimeout(() => {
      updateUser && updateUser(name, score + value, email)
      setTransitionTimeout(TRANSITION_TIME_OUT);
      setQuestionHidden(true);
      setTimeout(() => {
        setMin(1);
        setMax(100);
        setValue(50);
        setCelebrate(false);
        setAnimate(false)
        navigate(url);
        setTransitionTimeout(TRANSITION_TIME_IN);
        setQuestionHidden(false);
        setDisableSubmit(false)
      }, TRANSITION_TIME_OUT);
    }, 1000)
  }

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <LogoComponent />

      <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <BotMessageComponent hidden={questionHidden} timeout={transitionTimeout} text={sprintf(questions[props.index].question, name ? ", " + name : "")} />
        <AnswerSelectorComponent hidden={questionHidden} timeout={transitionTimeout} onChange={onValueChange} min={min} max={max} value={value} options={questions[props.index].options} />
        <Button onClick={()=>setCelebrate(true)} disabled={(max > 3)} variant="contained" sx={{paddingX: 6, marginY: 6}}>Next</Button>
      </Box>

      <ProgressIndicatorComponent position={props.index}/>
      
      <FancyMessageBox
        animate={animate}
        display={celebrate}
        badgeIndex={props.index}
        title={questions[props.index].title}
        onClick={onSubmit}
        disabledSubmit={disableSubmit}
        message={questions[props.index].reaction}
      />
    </Stack>
  )
}

export default QuestQuestion;
