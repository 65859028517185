import { Box, Button, Fade, Stack, Typography } from '@mui/material';
import LogoComponent from '../components/LogoComponent';
import { useState } from 'react';
import {
  MAX_WIDTH,
  TRANSITION_TIME_IN,
  TRANSITION_TIME_OUT
} from '../theme/gaimcontrol';
import { useNavigate } from 'react-router-dom';

var messages = [
  "You are about to embark in a trip that will change your life for best.",
  "But, before any trip, you got to pack and prepare for it.",
  "Answer the following questions and get ready for your trip.",
]

interface IntroComponentProps {
  onClick?:  () => void
}

function IntroComponent( props: IntroComponentProps ) {
  const [index, setIndex] = useState<number>(0)
  const [messageVisible, setMessageVisible] = useState<boolean>(false)
  const [transitionTime, setTransitionTime] = useState<number>(TRANSITION_TIME_IN)
  const [buttonVisible, setButtonVisible] = useState<boolean>(false)
  const [contentVisible, setContentVisible] = useState<boolean>(false)
  const navigate = useNavigate()

  const switchToNext = () => {
    if (index < (messages.length - 1))
    {
      setTransitionTime(TRANSITION_TIME_OUT)
      setMessageVisible(false)
      setTimeout(() => {
        setTransitionTime(TRANSITION_TIME_IN)
        setIndex((index + 1) % messages.length)
        setMessageVisible(true)
      }, TRANSITION_TIME_OUT)
    } else if (!buttonVisible) {
      setTransitionTime(TRANSITION_TIME_OUT)
      setButtonVisible(true)
    }
  }

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <LogoComponent animate onAnimationComplete={() => setMessageVisible(true)}/>
      <Fade in={messageVisible} timeout={transitionTime}>
        <Box
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          m={3}
          onClick={switchToNext}
          maxWidth={MAX_WIDTH}
        >
          <Typography width={"100%"} variant='h4' textAlign={"center"} m={6}>{messages[index]}</Typography>
          <Fade in={buttonVisible} timeout={transitionTime}>
            <Button variant='contained' sx={{paddingX: 6}} onClick={() => navigate("/acq")}>Get started</Button>
          </Fade>
        </Box>
      </Fade>
    </Stack>
  )
}

export default IntroComponent;
