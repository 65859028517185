import { Box, Grid, useMediaQuery } from "@mui/material";

import {
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';

interface ProgressIndicatorComponentProps {
  position: number
  big?: boolean
}

function ProgressIndicatorComponent(props: ProgressIndicatorComponentProps) {
  const isSmUp = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      <Grid
        container
        columnSpacing={props.big ? 2 : isSmUp ? 4 : 2}
        my={3}
        columns={9}
      >
      {
        Array(9).fill("").map((_, index) =>
          (<Grid item xs={1} justifyContent={"space-between"}>{index < props.position ?
            <Box width={props.big? "50px" : "30px"} component="img" src={`/badge${index + 1}.png`}/> : index === props.position ?
            <RadioButtonCheckedIcon sx={{color: "#d7baff", fontSize: props.big? "50px" : "30px"}}/> : 
            <RadioButtonUncheckedIcon sx={{color: "#ae8ddd", fontSize: props.big? "50px" : "30px"}}/>}
          </Grid>)
        )
      } 
      </Grid>
    </Box>
  )
}

export default ProgressIndicatorComponent;
