import './App.css';
import preval from 'preval.macro';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isMobile } from 'react-device-detect';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ReactGA from "react-ga4";

import { Box, Typography, Link } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import GaimControlTheme from './theme/gaimcontrol';
import IntroComponent from './routes/IntroComponent';
import AcquaintanceComponent from './routes/Acquaintance';
import QuestQuestion from './routes/QuestQuestion';
import FollowUpComponent from './routes/FollowUp';
import ResultComponent from './routes/Result';
import { UserConextProvider } from './context/UserContext';
import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import Component404 from './routes/404';

const router = createBrowserRouter([
  {
    path: "/",
    element: <IntroComponent />,
  },
  {
    path: "/acq",
    element: <AcquaintanceComponent />,
  },
  {
    path: "/quest0",
    element: <QuestQuestion index={0}/>,
  },
  {
    path: "/quest1",
    element: <QuestQuestion index={1}/>,
  },
  {
    path: "/quest2",
    element: <QuestQuestion index={2}/>,
  },
  {
    path: "/quest3",
    element: <QuestQuestion index={3}/>,
  },
  {
    path: "/quest4",
    element: <QuestQuestion index={4}/>,
  },
  {
    path: "/quest5",
    element: <QuestQuestion index={5}/>,
  },
  {
    path: "/quest6",
    element: <QuestQuestion index={6}/>,
  },
  {
    path: "/quest7",
    element: <QuestQuestion index={7}/>,
  },
  {
    path: "/quest8",
    element: <QuestQuestion index={8}/>,
  },
  {
    path: "/fin",
    element: <FollowUpComponent/>,
  },
  {
    path: "/result",
    element: <ResultComponent />,
  },
  {
    path: "*",
    element: <Component404 />
  }
])

function App() {
  useEffect(() => {
    const siteId = 5081401;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion)

    ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
  }, [])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string}>
      <ThemeProvider theme={GaimControlTheme}>
        <UserConextProvider>
          <Box
            width={"100%"}
            height={isMobile ? "100svh" : "100vh"}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              backgroundImage: "url('/background.webp')",
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              overflowY: "clip",
            }}
          >
            <Box flexGrow={1} width={"100%"} sx={{overflowY: "auto"}}>
              <RouterProvider router={router}/>
              <div style={{display:'none'}}>Build Date: {preval`module.exports = new Date().toLocaleString();`}.</div>
            </Box>
            <Typography textAlign={"right"} variant="caption">This site is protected by reCAPTCHA and the Google&nbsp;
              <Link href="https://policies.google.com/privacy">Privacy Policy</Link> and&nbsp;
              <Link href="https://policies.google.com/terms">Terms of Service</Link> apply.
            </Typography>
          </Box>
        </UserConextProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
