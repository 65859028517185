import { BottomNavigationClassKey, Box, Button, Modal, Typography } from "@mui/material";
import { keyframes } from '@emotion/react';

import { Bounce } from 'react-awesome-reveal';

interface FancyMessageBoxProps {
  display: boolean;
  animate: boolean;
  badgeIndex: number
  onClick?: () => void;
  title: string;
  message: string;
  disabledSubmit?: boolean;
}

// Define the keyframes for the animation
const jumpAndShrink = keyframes`
  0% {
    transform: translateY(0) scale(1);
  }
  30% {
    transform: translateY(-50px) scale(1);
  }
  100% {
    transform: translateY(60vh) scale(0);
  }
`;

function FancyMessageBox( props: FancyMessageBoxProps ) {
  return (
    <Modal open={props.display} disableEscapeKeyDown={true}>
      <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
       <Box
          maxWidth={"600px"}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          p={3}
          sx={{
            background: "rgb(42, 42, 42)",
            borderRadius: '28px',
            boxShadow: '0px 4px 43.2px 0px rgba(27, 27, 27, 1)'
          }}
        >
          <Bounce duration={1000}>
            <Box sx={{ animation: props.animate ? `${jumpAndShrink} 1s forwards` : "none" }} width={"160px"} mt={"-100px"} component="img" src={`/badge${props.badgeIndex + 1}.png`}/>
          </Bounce>
          <Typography px={3} textAlign={"center"} variant="h5" color={"white"}>{props.title}</Typography>
          <Typography px={3} my={3} textAlign={"center"} variant="body2" color={"white"}>{props.message}</Typography>
          <Button disabled={props.disabledSubmit} variant="contained" sx={{paddingX: 6}} onClick={props.onClick}>Go on</Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default FancyMessageBox;